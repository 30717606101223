import React from 'react';
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Form from '../layout/form.jsx'
import Loader from '../layout/loader.jsx'
import { toast } from 'react-toastify'
import API from '../../services/api.jsx'

class PlaylistNew extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			breadcrumb: {title: 'Back to playlists', path: '/assets/playlists'},
			fields: {
				name: { label: 'Name', type: 'text', required: true },
				type: { label: 'Playlist type', type: 'select', options: [
					{ key: 'vox', label: 'VOX Playlist' },
					{ key: 'custom', label: 'Tailor made' },
					{ key: 'sr_library', label: 'SR Library' }
				] },
				tag: { label: 'Tag', type: 'text', required: false },
			},
			entity: {
				name: null
			}
		}
		this.saveEntity = this.saveEntity.bind(this)
	}

	saveEntity(fields) {
		this.setState({ loading: true })
		fields.assets = []
		API.create('playlists', fields).then(() => {
			toast.success('Playlist has been created')
			this.props.history.push('/assets/playlists')
		}).catch(() => {
			toast.error('An unexpected error has occured, please try again')
			this.setState({ loading: false })
		})
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={'New playlist'} breadcrumb={this.state.breadcrumb} />
				<Content>
					<Form fields={this.state.fields} cancel={() => this.props.history.push('/assets/playlists')} save={this.saveEntity} entity={this.state.entity} />
				</Content>
			</>
	}
}

export default PlaylistNew;
