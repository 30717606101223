import React from 'react';


class Form extends React.Component {
	constructor(props) {
		super(props)
		this.state = props.entity
	}

	updateField(e, key) {
		this.setState({ [key]: e.target.value })
	}

	updateBoolean(value, key) {
		this.setState({ [key]: value })
	}

	onCancel(e, callback) {
		e.preventDefault()
		callback()
	}

	onSubmit(e, callback) {
		e.preventDefault()
		callback()
	}

	render() {
		return (
			<form onSubmit={(e) => this.onSubmit(e, () => this.props.save(this.state))}>
				<div className="actions clearfix">
					<div className="buttons clearfix">
						<div className="button grey" onClick={(e) => this.onCancel(e, () => this.props.cancel())}>Cancel</div>
						<button className="blue">Save</button>
					</div>
				</div>

				<div className="block">
					<div className="fields clearfix">
						{
							Object.keys(this.props.fields).map((field) => {
								return <div className="field" key={field}>
									<label>{ this.props.fields[field].label }</label>
									{
										this.props.fields[field].type === 'text' && <input type="text" onChange={(e) => this.updateField(e, field)} required={this.props.fields[field].required} value={this.state[field]} />
									}
									{
										this.props.fields[field].type === 'email' && <input type="email" onChange={(e) => this.updateField(e, field)} required={this.props.fields[field].required} value={this.state[field]} />
									}
									{
										this.props.fields[field].type === 'select' && <select onChange={(e) => this.updateField(e, field)} required={this.props.fields[field].required} value={this.state[field]}>
											{
												this.props.fields[field].options.map(option => <option value={option.key}>{option.label}</option>)
											}
										</select>
									}
									{
										this.props.fields[field].type === 'boolean' && <div className="radio">
										{console.log(this.props.fields[field])}
											<input type="radio" name={field} onChange={e => this.updateBoolean(true, field)} checked={this.state[field] == true ? true : false} />
											<span>Yes</span>
											<input type="radio" name={field} onChange={e => this.updateBoolean(false, field)} checked={this.state[field] == false ? true : false} />
											<span>No</span>
										</div>
									}
								</div>
							})
						}
					</div>
				</div>
			</form>
		)
	}
}


export default Form;

