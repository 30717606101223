import React from 'react';
import API from '../../services/api.jsx'
import Helpers from '../../services/helpers.jsx'
import Loader from '../layout/loader.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import { toast } from 'react-toastify'
import { debounce } from 'lodash'

class DeviceList extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			filters: {
				name: '',
				sku: ''
			},
			devices: {
				columns: [
				{
					label: 'Device name',
					key: 'name'
				}, 
				{
					label: 'SKU',
					key: 'sku'
				}, {
					label: 'Status',
					key: 'lastPing',
					view: function(field) {
						if(!field)
							return <div className="red">offline</div>

						let timestamp = + new Date(field)
						let now = + new Date()
						return now - timestamp > 60 * 60 * 1000 ? <div className="red">offline</div> : <div className="green">online</div>
					}
				}, {
					label: 'Storage left',
					key: 'storage',
					view: function(field){
						if(!field)
							return '-'
						return Helpers.formatBytes(field.externalAvailable) + '/' + Helpers.formatBytes(field.externalTotal)
					}
				}, {
					label: 'Playing',
					key: 'playing'
				}, {
					label: 'Timezone',
					key: 'timezone'
				}, {
					label: 'Software version',
					key: 'softwareVersion'
				}, {
					label: 'Download status',
					key: 'downloadStatus',
					view: function(field) {
						if(!field)
							return '-'
						else if(field.downloaded === field.total)
							return <div className="progress green"><i className="icon-done"></i> Downloaded</div>
						else
							return <div className="progress orange"><i className="icon-downloading"></i> {Math.round(field.downloaded/field.total*100)}% ({field.downloaded}/{field.total} tracks)</div>

					}
				}, {
					action: 'delete',
					onClick: (id, key) => {
						var r = window.confirm("Are you sure you want to delete this device?");
						if (r === false) return

						this.setState({ loading: true })
						API.delete('devices', id).then(() => {
							toast.success('Device was successfully deleted')
							this.loadEntities()
						}).catch(() => {
							toast.error('An unexpected error has occured')
							this.setState({ loading: false })
						})
					}
				}],
				entities: [],
				filters: [
				{
					label: 'Device name',
					onChange: debounce(value => {
						if(value !== this.state.filters.name)
							this.setState({ filters: { ...this.state.filters, name: value ? '/' + value + '/i' : '' }}, () => {
								this.loadEntities()
							})
					}, 500)
				},
				{
					label: 'SKU',
					onChange: debounce(value => {
						if(value !== this.state.filters.sku)
							this.setState({ filters: { ...this.state.filters, sku: value ? '/' + value + '/i' : '' }}, () => {
								this.loadEntities()
							})
					}, 500)
				}, {
					label: 'Search for a client',
					type: 'select',
					value: null,
					entities: [],
					onChange: value => {
						if(value !== this.state.filters.client)
							this.setState({ filters: { ...this.state.filters, client: value ? value.id : '' }, devices: { ...this.state.devices, filters: [this.state.devices.filters[0], this.state.devices.filters[1], { ...this.state.devices.filters[2], value: value }] }}, () => {
								this.loadEntities()
							})
					}
				}],
				actions: [],
				selectedItems: [],
				multiSelect: false,
				onRowClick: (entity) => {
					this.props.history.push('/devices/' + entity.id)
				}
			}
		}
	}

	componentDidMount() {
		API.getClients().then(clients => {
			// create index
			clients = [{ name: 'All clients', id: '' }].concat(clients)
			let clientIndex = clients.map(client => client.id)
			this.setState({ clients: clients, clientIndex: clientIndex, devices: { ...this.state.devices, filters: [this.state.devices.filters[0], this.state.devices.filters[1], { ...this.state.devices.filters[2], entities: clients, value: clients[0] }] } }, () => {
				this.loadEntities()
			})
		})
	}

	loadEntities() {
		API.getDevices(this.state.filters).then(entities => this.setState({ ...this.state, loading: false, devices: {...this.state.devices, entities: entities} }))
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={'Devices'} />
				<Content>
					<Table settings={this.state.devices} />
				</Content>
			</>
	}
}

export default DeviceList;
