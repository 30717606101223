import React from 'react';
import Logo from './logo'
import Nav from './nav'

const Header = () => (
	<header>
		<div className="viewport">
			<Logo />
			<Nav className="nav"/>
		</div>
	</header>
);

export default Header;
