import React from 'react';
import { Link } from "react-router-dom";

function Logo() {
	return (
		<Link to="/" className="logo icon-logo-vox"></Link>
	);
}
/*

*/

export default Logo;
