import Dictionary from './dictionary'
import DB from './db'
import moment from 'moment'
import 'moment/locale/fr'
import 'moment/locale/es'
import 'moment/locale/pt'
import 'moment/locale/it'
import 'moment/locale/de'
import 'moment/locale/ru'

let language = 'en'
moment.locale(language)

class i18n {
	constructor() {
		let languageFromStorage = DB.get('language')
		if(languageFromStorage)
			this.setLanguage(languageFromStorage)
	}

	t(code) {
		if(!Dictionary[code] || !Dictionary[code][language])
			throw new Error('Missing translation: `' + code + '`')

		return Dictionary[code][language]
	}

	authorizedLanguage() {
		return ['en', 'fr', 'es', 'pt', 'it', 'de', 'ru']
	}

	setLanguage(newLanguage) {
		if(this.authorizedLanguage().indexOf(newLanguage) > -1) {
			language = newLanguage
			DB.set('language', newLanguage)
			moment.locale(newLanguage)
		}

		else
			throw new Error('Language is not allowed: `' + newLanguage + '`')
	}

	getLanguage() {
		return language
	}
}

export default new i18n();