import React from 'react';
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import DeviceView from '../devices/viewDevice.jsx'

class ViewDevice extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			breadcrumb: { title: 'Device list', path: '/devices' },
			device: null,
			client: null
		}
	}

	componentDidMount() {
		API.getDevice(this.props.match.params.deviceId).then(device => {
			API.getClient(device.client).then((client) => {
				this.setState({ loading: false, device: device, client: client })
			})
		})
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={'Device ' + this.state.device.sku} breadcrumb={this.state.breadcrumb} tabs={this.state.tabs} edit={() => this.props.history.push('/devices/' + this.props.match.params.deviceId + '/edit')} />
				<Content>
					<DeviceView device={this.state.device} client={this.state.client} />
				</Content>
			</>
	}
}

export default ViewDevice;

