import React from 'react'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import { toast } from 'react-toastify'
import AudioPlayer from 'react-h5-audio-player'

class PlaylistView extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			clients: [],
			loading: false,
			playlist: null,
			breadcrumb: {title: 'Back to playlists', path: '/assets/playlists'},
			assets: {
				columns: [{
					label: '',
					key: 'id',
					className: 'no-wrap',
					view: field => {
						return <i className="icon-play" onClick={() => this.play(field)} />
					}
				}, {
					label: 'ISRC',
					key: 'isrc'
				}, {
					label: 'Title',
					key: 'name'
				}, {
					label: 'Artist',
					key: 'artist'
				}, {
					label: 'Label',
					key: 'label'
				}, {
					label: 'Duration',
					key: 'duration',
					view: function(field) {
						let hours = Math.floor(field / 60 / 60);
						let minutes = Math.floor(field / 60) - (hours * 60);
						let seconds = Math.round(field % 60);
						if(seconds < 10)
							seconds = '0' + Math.round(seconds)
						return minutes + ':' + seconds
					}
				}, {
					label: 'Bitrate',
					key: 'bitrate',
					view: function(field) {
						return Math.round(field / 1000) + 'kbps'
					}
				}, {
					label: 'Sample rate',
					key: 'sampleRate',
					view: function(field) {
						return (Math.round(field / 100) / 10) + 'kHz'
					}
				}, {
					action: 'delete',
					onClick: (id, key) => {
						var r = window.confirm("Are you sure you want to delete?");
						if (r === false) return
						let playlist = JSON.parse(JSON.stringify(this.state.playlist))
						if(playlist.assets) {
							playlist.assets = playlist.assets.map(asset => asset.id)
							playlist.assets.splice(key, 1)
						}
						this.setState({ loading: true })
						API.update('playlists', this.state.playlist.id, playlist).then(playlist => {
							API.getPlaylist(this.props.match.params.playlistId).then(playlist => this.setState({ ...this.state, loading: false, selectedAssetFile: null, playlist: playlist, assets: { ...this.state.assets, entities: playlist.assets } }))
						}).catch((err) => {
							console.log(err)
							this.setState({ loading: false, selectedAssetFile: null })
							alert('An unexpected error has occured')
						})
					}
				}],
				entities: [],
				filters: [
				// {
				// 	label: 'Search for a playlist',
				// 	onChange: function(){}
				// }
				],
				actions: [{
					classList: 'red delete',
					label: 'Delete media(s)',
					onClick: () => {
						var r = window.confirm("Are you sure you want to delete?");
						if (r === false) return

						let toDelete = []
						this.state.assets.selectedItems.forEach((value, key) => {
							if(value)
								toDelete.push(this.state.assets.entities[key])
						})

						if(toDelete.length === 0)
							return

						let playlist = JSON.parse(JSON.stringify(this.state.playlist))
						for (var i = this.state.assets.selectedItems.length -1; i >= 0; i--)
							if(this.state.assets.selectedItems[i] === true)
	   							playlist.assets.splice(i, 1);

   						playlist.assets = playlist.assets.map(asset => asset.id)

   						this.setState({ loading: true })
						API.update('playlists', this.state.playlist.id, playlist).then(playlist => {
							API.getPlaylist(this.props.match.params.playlistId).then(playlist => this.setState({ ...this.state, loading: false, playlist: playlist, assets: { ...this.state.assets, selectedItems: [], entities: playlist.assets } }))
						}).catch((err) => {
							console.log(err)
							this.setState({ loading: false, selectedAssetFile: null })
							alert('An unexpected error has occured')
						})
					}
				}, {
					classList: 'blue save',
					label: 'Add media',
					onClick: () => {
						this.uploadAsset.click()
					}
				}],
				selectedItems: [],
				multiSelect: true
			},
			selectedAssetFile: null
		}
	}

	play(id) {
		if(!id)
			return toast.error('Cannot play file: asset has no file attached')

		API.getMusicUrl(id).then(({ url }) => {
			if (url) {
				this.setState({ audio: url })
			} else {
				toast.error('Cannot play file: asset has no file attached')
			}
		});
	}

	componentDidMount() {
		API.getPlaylist(this.props.match.params.playlistId).then(playlist => this.setState({ ...this.state, playlist: playlist, assets: { ...this.state.assets, entities: playlist.assets } }))
	}

	onChangeAssetFile(event) {
		event.stopPropagation();
		event.preventDefault();

		// Check if there are files to upload
		if(event.target.files.length < 1)
			return

		// @todo, check all files extentions

		// Display loader
		this.setState({ loading: true })

		// Upload assets
		let uploadedAssets = []
		console.log(event.target.files)
		let uploads = []
		for (let i = 0; i < event.target.files.length; i++) {
			uploads.push(API.postAsset(event.target.files[i]).then(file => uploadedAssets.push(file.id)))
		}

		Promise.all(uploads).then((asset) => {
			this.savePlaylist(uploadedAssets)
		}).catch((err) => {
			console.log(err)
			this.setState({ loading: false, selectedAssetFile: null })
			toast.error('An unexpected error has occured when uploading files')
		})
	}

	savePlaylist(uploadedAssets) {
		if(!uploadedAssets)
			uploadedAssets = []
		let playlist = { ...this.state.playlist }
		if(playlist.assets) {
			playlist.assets = playlist.assets.map(asset => asset.id)
			playlist.assets = playlist.assets.concat(uploadedAssets)
		} else {
			playlist.assets = uploadedAssets
		}
		API.update('playlists', this.state.playlist.id, playlist).then(playlist => {
			API.getPlaylist(this.props.match.params.playlistId).then(playlist => this.setState({ ...this.state, loading: false, selectedAssetFile: null, playlist: playlist, assets: { ...this.state.assets, entities: playlist.assets } }))
			toast.success('Files added to playlist')
		}).catch((err) => {
			console.log(err)
			this.setState({ loading: false, selectedAssetFile: null })
			toast.error('An unexpected error has occured when uploading files')
		})
	}

	onChangeCoverFile(event) {
		event.stopPropagation();
		event.preventDefault();

		// Check if there are files to upload
		if(event.target.files.length < 1)
			return

		// @todo, check all files extentions

		// Display loader
		this.setState({ loading: true })

		API.postPlaylistCover(event.target.files[0]).then(file => {
			this.state.playlist.cover = file.cover
			this.state.playlist.coverHash = file.coverHash
			this.savePlaylist()
		})
	}

	playlistCover() {
		return <div className="playlist-cover" style={{backgroundImage: 'url(' + API.apiURL + '/storage/playlist-cover/' + (this.state.playlist.cover && this.state.playlist.cover.uri) + ')'}} onClick={(e) => { this.uploadCover.click() }}></div>
	}

	render() {
		if(!this.state.playlist)
			return <Loader />
		else
			return <>
				<Head title={this.state.playlist.name} beforeTitle={this.playlistCover.bind(this)} breadcrumb={this.state.breadcrumb} edit={() => this.props.history.push('/assets/playlists/' + this.props.match.params.playlistId + '/edit')} />
				<Content>
					{
						 this.state.loading === true ? <Loader /> : <>
							<input
								type="file"
								ref={(ref) => this.uploadAsset = ref}
								style={{display: 'none'}}
								onChange={this.onChangeAssetFile.bind(this)}
								value={this.state.selectedAssetFile}
								multiple
								accept=".mp3"
							/>
							<input
								type="file"
								ref={(ref) => this.uploadCover = ref}
								style={{display: 'none'}}
								onChange={this.onChangeCoverFile.bind(this)}
								value={this.state.selectedCoverFile}
								accept=".jpg,.png,.jpeg"
							/>
							<Table settings={this.state.assets} />
							<div className="push-player"></div>
							<AudioPlayer
								autoPlay
								src={this.state.audio}
								onPlay={e => console.log("onPlay")}
							/>
						 </>
					}
				</Content>
			</>
	}
}

export default PlaylistView;
