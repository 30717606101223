import React from 'react';

function Head(props) {
	return (
		<div className="content">
			<div className="viewport">
				{props.children}
			</div>
		</div>
	);
}

export default Head;
