import React from 'react';
import API from '../../../services/api.jsx'
import Loader from '../../layout/loader.jsx'
import Table from '../../layout/table.jsx'
import moment from 'moment'
import { toast } from 'react-toastify'
import { debounce } from 'lodash'

class CampaignsList extends React.Component {
	constructor(props) {
		super(props)
		let that = this

		this.state = {
			loading: true,
			filters: {
				name: '',
				client: this.props.match.params.clientId
			},
			campaigns: {
				columns: [{
					label: 'Campaign name',
					key: 'name'
				}, {
					label: 'Duration',
					key: 'infinite',
					view: (field, entity) => {
						return field === true ? 'Infinite' : moment(entity.startDate).format('L') + ' - ' + moment(entity.endDate).format('L')
					}
				}, {
					label: 'Status',
					key: 'status',
					view: field => {
						return field === 'on' ? <div className="green">Ongoing</div> : <div className="red">Off</div>
					}
				}, {
					label: 'Devices',
					key: 'devices',
					view: function(field) {
						return field.length
					}
				}, {
					label: 'Priority',
					key: 'priority'
				}, {
					label: 'Last update',
					key: 'updatedAt',
					view: function(field){
						return moment(field).format('l')
					}
				}, {
					action: 'delete',
					onClick: (id, key) => {
						var r = window.confirm("Are you sure you want to delete this campaign?");
						if (r === false) return

						this.setState({ loading: true })
						API.delete('campaigns', id).then(() => {
							toast.success('Campaign was successfully deleted')
							this.loadEntities()
						}).catch(() => {
							toast.error('An unexpected error has occured')
							this.setState({ loading: false })
						})
					}
				}],
				entities: [],
				filters: [{
					label: 'Search for a campaign',
					onChange: debounce(value => {
						if(value !== this.state.filters.name)
							this.setState({ filters: { ...this.state.filters, name: value ? '/' + value + '/i' : '' }}, () => {
								this.loadEntities()
							})
					}, 500)
				}],
				actions: [{
					classList: 'blue add',
					label: 'New campaign',
					onClick: () => {
						this.props.history.push('/clients/' + props.match.params.clientId + '/campaigns/new')
					}
				}],
				selectedItems: [],
				multiSelect: false,
				onRowClick: function(entity) {
					that.props.history.push('/clients/' + props.match.params.clientId + '/campaigns/' + entity.id)
				}
			}
		}
	}

	componentDidMount() {
		this.loadEntities()
	}

	loadEntities() {
		API.getCampaigns(this.state.filters).then(entities => this.setState({ ...this.state, loading: false, campaigns: {...this.state.campaigns, entities: entities} }))
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Table settings={this.state.campaigns} />
		</>
	}
}

export default CampaignsList;
