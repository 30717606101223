import React from 'react';
import { NavLink } from "react-router-dom";
import User from '../../services/user.jsx'
import { useHistory } from "react-router-dom"

function Nav(props) {
	let history = useHistory()

	function logout(e) {
		e.preventDefault()
		User.logout()
		history.push('/')
	}
	
	return (
		<nav>
			<ul>
				<li>
					<NavLink activeClassName="active" to="/clients">
						Clients
					</NavLink>
				</li>
				<li>
					<NavLink activeClassName="active" to="/devices">
						Devices
					</NavLink>
				</li>
				<li>
					<NavLink activeClassName="active" to="/campaigns">
						Campaigns
					</NavLink>
				</li>
				<li>
					<NavLink activeClassName="active" to="/assets">
						Playlists
					</NavLink>
				</li>
				<li>
					<a href="#" onClick={logout}>
						Logout
					</a>
				</li>
			</ul> 
		</nav>
	);
}

export default Nav;
