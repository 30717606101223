import React from 'react';
import Helpers from '../../services/helpers.jsx'

function DeviceView(props) {
	function deviceStatus(lastPing) {
		if(!lastPing)
			return <div className="red">offline</div>

		return (+ new Date(lastPing) > + new Date() - 60 * 60 * 1000) ? <div className="green">online</div> : <div className="red">offline</div>
	}

	function deviceStorage(field) {
		if(!field)
			return '-'

		return (field.externalAvailable / field.externalTotal) < 0.3 ?
			<div className="red">{Helpers.formatBytes(field.externalTotal - field.externalAvailable) + '/' + Helpers.formatBytes(field.externalTotal)}</div>
			:
			<div className="green">{Helpers.formatBytes(field.externalTotal - field.externalAvailable) + '/' + Helpers.formatBytes(field.externalTotal)}</div>
	}

	function downloadStatus(field) {
		if(!field)
			return '-'
		else if(field.downloaded === field.total)
			return <div className="green">Downloaded ({field.downloaded}/{field.total})</div>
		else
			return <div className="orange">{Math.round(field.downloaded/field.total)}% ({field.downloaded}/{field.total} tracks)</div>
	}

	return <>
		<div className="block">
			<div className="device-info">
				<div className="cell">
					<i className="icon-sku"></i>
					<span>SKU</span>
					<div>{ props.device.sku || '-' }</div>
				</div>

				<div className="cell">
					<i className="icon-client"></i>
					<span>Client</span>
					<div>{ props.client.name || '-' }</div>
				</div>

				<div className="cell">
					<i className="icon-device"></i>
					<span>Device Type</span>
					<div>{ (props.device.deviceType && props.device.deviceType.device) || '-' }</div>
				</div>

				<div className="cell">
					<i className="icon-status"></i>
					<span>Status</span>
					{ deviceStatus(props.device.lastPing) }
				</div>

				<div className="cell">
					<i className="icon-connectivity"></i>
					<span>Connectivity</span>
					{ props.device.connectivity || '-' }
				</div>

				<div className="cell">
					<i className="icon-timezone"></i>
					<span>Timezone</span>
					{ props.device.timezone || '-' }
				</div>

				<div className="cell">
					<i className="icon-settings-3"></i>
					<span>Software version</span>
					{ props.device.softwareVersion || '-' }
				</div>

				<div className="cell">
					<i className="icon-storage"></i>
					<span>Storage used</span>
					{ deviceStorage(props.device.storage) }
				</div>

				<div className="cell">
					<i className="icon-name"></i>
					<span>Download status</span>
					{ downloadStatus(props.device.downloadStatus) || '-' }
				</div>
			</div>
		</div>

	</>
}

export default DeviceView;
