let dictionary = {
	// Common terms
	CONFIRM: {
		en: 'Confirm',
		fr: 'Confirmer'
	},
	CANCEL: {
		en: 'Cancel',
		fr: 'Annuler'
	},
	SAVE: {
		en: 'Save',
		fr: 'Sauvegarder'
	},
	STREET: {
		en: 'Street',
		fr: 'Rue'
	},
	OTHER: {
		en: 'Other',
		fr: 'Autre'
	},
	CITY: {
		en: 'City',
		fr: 'Ville'
	},
	ZIP: {
		en: 'Zip code',
		fr: 'Code postal'
	},
	COUNTRY: {
		en: 'Country',
		fr: 'Pays'
	},
	SECONDS: {
		en: 'sec',
		fr: 's'
	},
	REBOOTING: {
		en: 'Rebooting',
		fr: 'Redémarrage en cours'
	},
	GROUPS: {
		en: 'Labels',
		fr: 'Groupes'
	},
	ADD_GROUP: {
		en: 'Add Groups',
		fr: 'Créer un groupe'
	},
	DRAG_TO_UPLOAD: {
		en: 'Drag file to upload',
		fr: 'Todo'
	},
	UPLOAD_PHOTO: {
		en: 'Upload Photo',
		fr: 'Todo'
	},
	REJECT_FILE: {
		en: 'Incorrect file type',
		fr: 'Todo'
	},
	VIDEO: {
		en: 'Videos',
		fr: 'Todo'
	},
	IMAGE: {
		en: 'Images',
		fr: 'Todo'
	},
	WEATHER: {
		en: 'Weather',
		fr: 'Todo'
	},
	TEXT: {
		en: 'Text',
		fr: 'Texte'
	},
	WEBSITE: {
		en: 'Website',
		fr: 'Site internet'
	},
	SCHEDULE: {
		en: 'Schedule',
		fr: 'Todo'
	},
	FROM: {
		en: 'From',
		fr: 'Todo'
	},
	TO: {
		en: 'To',
		fr: 'Todo'
	},
	MONDAY: {
		en: 'Monday',
		fr: 'Lundi'
	},
	TUESDAY: {
		en: 'Tuesday',
		fr: 'Mardi'
	},
	WEDNESDAY: {
		en: 'Wednesday',
		fr: 'Mercredi'
	},
	THURSDAY: {
		en: 'Thursday',
		fr: 'Jeudi'
	},
	FRIDAY: {
		en: 'Friday',
		fr: 'Vendredi'
	},
	SATURDAY: {
		en: 'Saturday',
		fr: 'Samedi'
	},
	SUNDAY: {
		en: 'Sunday',
		fr: 'Dimanche'
	},
	DATE: {
		en: 'Date',
		fr: 'Date'
	},
	TIME: {
		en: 'Time',
		fr: 'Todo'
	},
	DEVICE: {
		en: 'Device',
		fr: 'Todo'
	},
	SETTINGS: {
		en: 'Settings',
		fr: 'Todo'
	},

	// Login page
	LOGIN_HERO_TITLE: { 
		en: 'Manage your kiosks, remotely!',
		fr: 'Gérez vos kiosques, à distance !',
	},
	LOGIN_HERO_SUBTITLE: { 
		en: 'Keep and eye on your devices. <br />Manage your kiosks, remotely. <br />Deploy your content faster and better.',
		fr: 'TODO',
	},
	LOGIN_FORM_TITLE: { 
		en: 'Sign in',
		fr: 'Connexion',
	},
	FIELD_EMAIL: { 
		en: 'Email',
		fr: 'Email',
	},
	FIELD_EMAIL_PLACEHOLDER: { 
		en: 'email@example.com',
		fr: 'email@example.com',
	},
	FIELD_PASSWORD: { 
		en: 'Password',
		fr: 'Mot de passe',
	},
	FIELD_PASSWORD_PLACEHOLDER: { 
		en: 'password',
		fr: 'mot de passe',
	},
	LOGIN_FORM_FORGOT_PASSWORD: { 
		en: 'Forgot your password?',
		fr: 'Mot de passe oublié ?',
	},
	LOGIN_FORM_SUBMIT: { 
		en: 'Sign in',
		fr: 'Connexion',
	},
	REMEMBER_ME: {
		en: 'Remember me?',
		fr: 'Todo'
	},
	NO_ACCOUNT_YET: {
		en: "Don't have an account yet? ",
		fr: 'Todo'
	},
	SIGN_UP: {
		en: 'Sign up',
		fr: 'Todo'
	},
	FIELD_USERNAME: {
		en: 'Username',
		fr: 'Todo'
	},

	// Menu
	NAV_DASHBOARD: { 
		en: 'Dashboard',
		fr: 'Accueil',
	},
	NAV_DEVICES: { 
		en: 'Devices',
		fr: 'Appareils',
	},
	NAV_MAP: { 
		en: 'Map',
		fr: 'Carte',
	},
	NAV_PUBLICATIONS: { 
		en: 'Publications',
		fr: 'Programmes',
	},
	NAV_ADMIN: { 
		en: 'Admin',
		fr: 'Todo',
	},

	// Dashboard
	DASHBOARD_MAP: { 
		en: 'Devices map',
		fr: 'Carte',
	},
	DASHBOARD_STATISTICS: { 
		en: 'Statistics',
		fr: 'Statistiques',
	},
	DASHBOARD_STATISTICS_ACTIVE_DEVICE: { 
		en: 'Active devices',
		fr: 'Appareils actifs',
	},
	DASHBOARD_STATISTICS_GEL_LEVEL: { 
		en: 'Average gel level',
		fr: 'Niveau de gel moyen',
	},
	DASHBOARD_STATISTICS_USES_TODAY: { 
		en: 'Uses today',
		fr: 'Utilisations aujourd\'hui',
	},

	// Devices
	DEVICES_LIST_TITLE: {
		en: 'Devices',
		fr: 'Appareils'
	},
	DEVICES_LIST_ADD: {
		en: 'Add new device',
		fr: 'Ajouter un appareil'
	},
	DEVICES_LIST_HELP: {
		en: 'Lorem ipsum dolor sit amet consequetum',
		fr: 'Lorem ipsum dolor sit amet consequetum'
	},
	DEVICES_LIST_COLUMN_NAME: {
		en: 'Device',
		fr: 'Nom de l\'appareil'
	},
	DEVICES_LIST_COLUMN_LEVEL: {
		en: 'Gel level',
		fr: 'Niveau de gel'
	},
	DEVICES_LIST_COLUMN_STATUS: {
		en: 'Status',
		fr: 'Statut'
	},
	DEVICES_LIST_COLUMN_GROUP: {
		en: 'Groups',
		fr: 'Groupes'
	},
	DEVICES_LIST_COLUMN_LOCATION: {
		en: 'Location',
		fr: 'Emplacement'
	},
	DEVICES_LIST_COLUMN_ACTION: {
		en: 'Actions',
		fr: 'Actions'
	},
	DEVICES_LIST_ACTION_VIEW: {
		en: 'View device',
		fr: 'Voir l\'appareil'
	},
	DEVICES_LIST_NEW_REPORT: {
		en: 'New Report',
		fr: 'Todo'
	},
	DEVICES_REPORT_TYPE: {
		en: 'Maintenance Type',
		fr: 'Todo'
	},
	DEVICES_REPORT_MESSAGE: {
		en: 'Description',
		fr: 'Todo'
	},
	DEVICES_REPORT_ADD_PICTURE: {
		en: 'Add a picture',
		fr: 'Todo'
	},
	// Device view
	DEVICE_NAME: {
		en: 'Device name',
		fr: 'Nom de l\'appareil'
	},
	DEVICE_ID: {
		en: 'Device ID',
		fr: 'ID de l\'appareil'
	},
	DEVICE_REF: {
		en: 'Reference',
		fr: 'Référence'
	},
	DEVICE_SKU: {
		en: 'Sku',
		fr: 'Todo'
	},
	DEVICE_CLIENT: {
		en: 'Client',
		fr: 'Todo'
	},
	DEVICE_LOCATION_NAME: {
		en: 'Location',
		fr: 'Emplacement'
	},
	DEVICE_LOCATION_CITY: {
		en: 'City',
		fr: 'Ville'
	},
	DEVICE_TYPE: {
		en: 'Device Type',
		fr: 'Todo'
	},
	DEVICE_STATUS: {
		en: 'Status',
		fr: 'Statut'
	},
	DEVICE_LAST_CONNECTION: {
		en: 'Last connection',
		fr: 'Dernière connexion'
	},
	DEVICE_CONNECTIVITY: {
		en: 'Connectivity',
		fr: 'Connectivité'
	},
	DEVICE_GEL_LEVEL: {
		en: 'Gel level',
		fr: 'Niveau de gel'
	},
	DEVICE_STORAGE: {
		en: 'Storage',
		fr: 'Espace de stockage'
	},
	DEVICE_LOCATION: {
		en: 'Location',
		fr: 'Emplacement'
	},
	DEVICE_GROUPS: {
		en: 'Groups',
		fr: 'Groupes'
	},
	DEVICE_HISTORY: {
		en: 'History',
		fr: 'Historique'
	},
	DEVICE_EDIT_DEVICE: {
		en: 'Edit Device',
		fr: 'Editer'
	},
	DEVICE_HISTORY_REPORT_TYPE_AUTO: {
		en: 'Auto Report',
		fr: 'Rapport automatique'
	},
	DEVICE_HISTORY_REPORT_TYPE_MANUAL: {
		en: 'Manual Report',
		fr: 'Rapport manuel'
	},
	DEVICE_HISTORY_CREATE_REPORT: {
		en: 'Create report',
		fr: 'Créer un rapport'
	},
	RETURN_TO_DEVICE: {
		en: 'Return to device',
		fr: 'Todo'
	},
	RETURN_TO_LIST: {
		en: 'Return to devices',
		fr: 'Todo'
	},
	RETURN_TO_PUBLICATION_LIST: {
		en: 'Return to publication',
		fr: 'Todo'
	},
	DEVICE_REBOOTING: {
		en: 'Device is currently rebooting',
		fr: 'Todo'
	},
	DEVICE_NOTIFICATION_SETTINGS: {
		en: 'Notification Settings',
		fr: 'Todo'
	},
	// PUBLICATIONS
	PUBLICATION_SCHEDULE: {
		en: 'Schedule',
		fr: 'Todo'
	},
	EDIT_PUBLICATION: {
		en: 'Edit Publication',
		fr: 'Todo'
	},
	PUBLICATION_NO_CONTENT: {
		en: 'No content added yet',
		fr: 'Todo'
	},
	SHOW_CONTENT: {
		en: 'Show content',
		fr: 'Todo'
	},
	HIDE_CONTENT: {
		en: 'Hide content',
		fr: 'Todo'
	},
	PUBLICATION_NAME: {
		en: 'Publication Name',
		fr: 'Todo'
	},
	ADD_CONTENT: {
		en: 'Add Content',
		fr: 'Todo'
	},
	NONE: {
		en: 'None',
		fr: 'Todo'
	},
	ADD_TEXT: {
		en: 'Add Text',
		fr: 'Todo'
	},
	ADD_WEBSITE: {
		en: 'Add Website',
		fr: 'Todo'
	},
	SELECTED_CONTENT: {
		en: 'Selected Content',
		fr: 'Todo'
	},
	REPETITION: {
		en: 'Repetition',
		fr: 'Todo'
	},
	// Users list & create / edit
	USERS_NEW_TITLE: {
		en: 'Create new user',
		fr: 'Créer un nouvel utilisateur'
	},
	DEVICE_REBOOT: {
		en: 'Reboot Device',
		fr: 'Todo',
	},
	DEVICE_REBOOT_TITLE: {
		en: 'Are you sure you want to reboot this device?',
		fr: 'Todo'
	},
	DEVICE_REBOOT_TEXT: {
		en: 'Please confirn that you want to reboot the device. It will be temporarily disconnected from the network and will restart.',
		fr: 'Todo'
	},
	//ADMIN
	ALERTS: {
		en: 'Alerts',
		fr: 'Todo'
	},
	// GROUPS
	GROUPS_TABLE_TITLE: {
		en: 'Manage Device Groups',
		fr: 'Todo'
	},
	GROUPS_TABLE_NAME: {
		en: 'Name',
		fr: 'Todo'
	},
	GROUPS_TABLE_DEVICES: {
		en: 'Devices',
		fr: 'Todo'
	},
	GROUPS_TABLE_PROGRAMS: {
		en: 'Programs',
		fr: 'Todo'
	},
	GROUPS_TABLE_ADD: {
		en: 'Add New Group',
		fr: 'Todo'
	},
	USERS: {
		en: 'Users',
		fr: 'Todo'
	},
	LANGUAGES: {
		en: 'Languages',
		fr: 'Todo'
	}
};

export default dictionary;