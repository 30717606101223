import React from 'react';
import PrivateRoute from '../layout/privateRoute'
import Loader from '../layout/loader.jsx'
import API from '../../services/api.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import ClientCampaigns from './campaigns/list.jsx'
import ClientDevices from './devices/list.jsx'
import ClientPlaylists from './playlists/list.jsx'
import ClientCollections from './collections/list.jsx'

class ClientList extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loaded: false,
			breadcrumb: {title: 'Back to client list', path: '/clients'},
			tabs: {
				param: props.match.params.clientId,
				links: [
					{ title: 'Campaigns', path: '/clients/:param/campaigns' },
					{ title: 'Devices', path: '/clients/:param/devices' },
					{ title: 'Playlists', path: '/clients/:param/playlists' },
					{ title: 'Collections', path: '/clients/:param/collections' }
				]
			},
			date: null,
			client: null
		}
	}

	componentDidMount() {
		API.getClient(this.props.match.params.clientId).then(client => {
			this.setState({ client: client })
		})
	}

	render() {
		if(!this.state.client)
			return <Loader />
		else
			return <>
				<Head title={this.state.client.name} breadcrumb={this.state.breadcrumb} tabs={this.state.tabs} edit={() => this.props.history.push('/clients/' + this.props.match.params.clientId + '/edit')} />
				<Content>
					<PrivateRoute exact path="/clients/:clientId/campaigns" component={ClientCampaigns} />
					<PrivateRoute exact path="/clients/:clientId/devices" component={ClientDevices} />
					<PrivateRoute exact path="/clients/:clientId/playlists" component={ClientPlaylists} />
					<PrivateRoute exact path="/clients/:clientId/collections" component={ClientCollections} />
				</Content>
			</>
	}
}

export default ClientList;
