import React from 'react';
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Form from '../layout/form.jsx'
import Loader from '../layout/loader.jsx'
import { toast } from 'react-toastify'
import API from '../../services/api.jsx'

class CollectionNew extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			breadcrumb: {title: 'Back to collections', path: '/assets/collections'},
			fields: {
				name: { label: 'Name', type: 'text', required: true },
			},
			entity: {
				name: null
			}
		}
		this.saveEntity = this.saveEntity.bind(this)
	}

	saveEntity(fields) {
		this.setState({ loading: true })
		fields.playlists = []
		API.create('collections', fields).then(() => {
			toast.success('Collection has been created')
			this.props.history.push('/assets/collections')
		}).catch(() => {
			toast.error('An unexpected error has occured, please try again')
			this.setState({ loading: false })
		})
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={'New collection'} breadcrumb={this.state.breadcrumb} />
				<Content>
					<Form fields={this.state.fields} cancel={() => this.props.history.push('/assets/collections')} save={this.saveEntity} entity={this.state.entity} />
				</Content>
			</>
	}
}

export default CollectionNew;
