import React from 'react';
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Form from '../layout/form.jsx'
import Loader from '../layout/loader.jsx'
import { toast } from 'react-toastify'
import API from '../../services/api.jsx'

class CollectionEdit extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			breadcrumb: {title: 'Back to collection', path: '/assets/collections/' + props.match.params.collectionId},
			fields: {
				name: { label: 'Name', type: 'text', required: true },
			},
			entity: null
		}
		this.saveEntity = this.saveEntity.bind(this)
	}

	componentDidMount() {
		API.getCollection(this.props.match.params.collectionId).then(client => this.setState({ loading: false, entity: client }))
	}

	saveEntity(fields) {
		this.setState({ loading: true })
		if(fields.playlists)
			fields.playlists = fields.playlists.map(asset => asset.id)
		else
			fields.playlists = []

		API.update('collections', this.props.match.params.collectionId, fields).then(() => {
			toast.success('Collection has been updated')
			this.props.history.push('/assets/collections/' + this.props.match.params.collectionId)
		}).catch(() => {
			toast.error('An unexpected error has occured, please try again')
			this.setState({ loading: false })
		})
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={'Edit collection'} breadcrumb={this.state.breadcrumb} />
				<Content>
					<Form fields={this.state.fields} cancel={() => this.props.history.push('/assets/collections/' + this.props.match.params.collectionId)} save={this.saveEntity} entity={this.state.entity} />
				</Content>
			</>
	}
}

export default CollectionEdit;
