import React from 'react';
import { Link, NavLink } from "react-router-dom";


function Head(props) {
	
	return (
		<div className="head">
			<div className="viewport">
				<h1>{props.beforeTitle && props.beforeTitle()}{props.title}{props.edit && <i className="icon-edit" onClick={(e) => { e.preventDefault(); props.edit() }}></i>}</h1>

				{
					// If breadcrumb
					props.breadcrumb && props.breadcrumb.title && props.breadcrumb.path ? 
						<Link className="breadcrumb" to={props.breadcrumb.path}><i className="icon-arrowback"></i> {props.breadcrumb.title}</Link> : null
				}

				{
					// If tabs
					props.tabs && props.tabs.links && props.tabs.links.length > 0 ? 
						<div className="tabs">{props.tabs.links.map((tab, i) => <NavLink key={`tab-${i}`} className="tab" activeClassName="active" to={tab.path.replace(':param', props.tabs.param)}>{tab.title}</NavLink>)}</div> : null
				}
			</div>
		</div>
	);
}


export default Head;

